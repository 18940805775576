<template>
  <section id="grading-assessment">
    <Breadcrumb
        :links="[
          {
            label: `<  ${course_title} `,
            link: true,
            route: { name: 'Instructor Grading Course' , params: { id: $route.params.course_id }},
          },
          {
            label: `${assessment_title}`,
            link: false,
            route: null,
          },
        ]"
      />
    
    <section class="d-flex flex-wrap align-center justify-space-between">
      <SearchBar :placeholder="'Search student name'" :value.sync="search" @search="getStudentList()" class="mb-5"/>
      <section class="d-flex flex-row ml-auto">
        <v-btn
            outlined
            color="primary"
            class="poppins f12 fw600 text-capitalize mx-1 mb-5"
            @click="markAsCompleted"
            dense
            :loading="loading1"
        >
            Mark as Completed
        </v-btn>
        <v-btn
            outlined
            color="primary"
            class="poppins f12 fw600 text-capitalize mx-1 mb-5"
            @click="markAsPending"
            dense
            :loading="loading2"
        >
            Mark as Pending
        </v-btn>
    </section>
    </section>
    

    <v-data-table
      :headers="grading_assessment_student_tbl"
      :items="gradingAssessment"
      class="text--center poppins custom-border"
      hide-default-footer
      :items-per-page.sync="paginate"
      :loading="loading">
      <template v-slot:header.select="{ item }" >
        <v-checkbox  class="mt-0" v-model="selectAll" hide-details/>
      </template>
      <template v-slot:item.select="{ item }" >
        <v-checkbox  class="mt-0" v-model="selected" :value="item.id" hide-details/>
      </template>
      <template v-slot:item.checked="{ item }">
        <span :class="item.checked == 'Completed' ? 'green--text' : 'grey--text'">{{ item.checked }}</span>
      </template>
      <template v-slot:item.date="{ item }">
        {{$dateFormat.mmDDyy(item.created_at)}}
      </template>
      <template v-slot:item.time="{ item }">
        {{$dateFormat.hhMM(item.created_at)}}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          text
          exact
          class="primary--text text-capitalize roboto f14"
          :to="{
            name: 'Instructor Grading Assessment User',
            params: { id: item.assessment_id, user_assessment_id: item.id, user_id: item.user.id },
          }"
          icon
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <FormPagination 
        :pageCount="pageCount" 
        :page="page"
        :paginate="paginate"
        @page="(e) => { page = e, getStudentList()}" 
        @paginate="(e) => { paginate = e, page = 1, getStudentList()}"/>

  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import Breadcrumb from "../../layouts/teacher/InstructorBreadcrumbs.vue";
import { grading_assessment_student_tbl } from '../../constants/tblheaders/grading';

export default {
  data: () => ({
    grading_assessment_student_tbl,
    search: '',
    pageCount: 1,
    page: 1,
    paginate: '10',
    loading: false,
    loading1: false,
    loading2: false,
    selected: [],
    selectAll: false,
    course_title: '',
    assessment_title: ''
  }),
  methods: {
    ...mapActions('instructor', ['getGradingStudentList', 'updateGradingStatusAction']),

    ...mapMutations(['alertMutation']),

    getStudentList() {
      this.selectAll = false
      this.selected = []

      this.loading = true
      this.getGradingStudentList({id: this.$route.params.id, search: this.search, page: this.page, paginate: Number(this.paginate)}).then(res => {
        this.page = res.assessment_scores.current_page
        this.pageCount = res.assessment_scores.last_page
        this.paginate = String(res.assessment_scores.per_page)
        this.course_title = res.course
        this.assessment_title = res.assessment.title
      }).finally(()=>{
        this.loading = false
      })
    },

    markAsCompleted(){
      this.loading1 = true
      this.updateGradingStatusAction({
        id: this.$route.params.id,
        assessment_scores_id: this.selected,
        status: true
      }).then(() => {
        this.alertMutation({
          show: true,
          text: `Successfully updated grading status`,
          type: "success"
        })
        this.getStudentList()
      }).catch(() => {
        this.alertMutation({
          show: true,
          text: `Something went wrong.`,
          type: "error"
        })
      }).finally(() => {
        this.loading1 = false
      })
    },

    markAsPending(){
      this.loading2 = true
      this.updateGradingStatusAction({
        id: this.$route.params.id,
        assessment_scores_id: this.selected,
        status: false
      }).then(() => {
        this.alertMutation({
          show: true,
          text: `Successfully updated grading status`,
          type: "success"
        })
        this.getStudentList()
      }).catch(() => {
        this.alertMutation({
          show: true,
          text: `Something went wrong.`,
          type: "error"
        })
      }).finally(() => {
        this.loading2 = false
      })
    }
  },
  mounted(){
    if(!this.$route.query || !this.$route.query.page) {
      this.$router.replace({ query: { page: 1 , timestamp: Date.now() } });
    } else {
      this.$router.replace({ query: { page: this.$route.query.page , timestamp: Date.now() } });
    }

    this.getStudentList()
  },
  watch: {
    selectAll(val) {
      if(val) {
        let _selected = []

        this.gradingAssessment.forEach(item => {
          if(!this.selected.includes(item)) {
            _selected.push(item.id)
          }
        })

        this.selected = [..._selected]

      } else if (!val && this.gradingAssessment.length === this.selected.length) {
        this.selected = [] 
      }
    },

    selected(val){
      if(val.length !== this.gradingAssessment.length) {
        this.selectAll = false
      } else if (val.length === this.gradingAssessment.length && this.gradingAssessment.length !== 0) {
        this.selectAll = true
      }
    }
  },
  components: { Breadcrumb },
  computed:{
    ...mapState('instructor', {
      grading: (state) => state.grading,
      gradingAssessment(state){
        let items = []
        state.gradingUsers.forEach(item => {
          items.push(
            { 
              created_at: item.created_at,
              student: `${item.user?.first_name ? item.user?.first_name : ''} ${item.user?.last_name ? item.user?.last_name : ''} ${item.user?.suffix ? item.user?.suffix : ''}`, 
              score: state.grading.assessment_questions_sum_score ? `${item.score} / ${state.grading.assessment_questions_sum_score} pts` : '-',
              checked: item.checked ? "Completed" : "Pending",
              assessment_id: state.grading.id,
              user: item.user,
              id: item.id })
        })
        return items
      }
    }),
  },
};
</script>

<style></style>

